<template>
	<section class="forgotPasswordPopin">
		<v-card class="forgotPasswordPopin__card">
			<v-form
			ref="form"
			@submit.prevent="sendRecoveryRequest()"
			class="form"
			>
				<div class="form-group">
					<v-card-title class="customTitle">
						Récupération de mot de passe
					</v-card-title>

					<p class="tw-text-left tw-m-0 tw-mb-[20px] tw-text-[rgba(0,0,0,0.6)]">
						Si l'adresse mail fournie est connue de nos services, un email vous sera
						envoyé à l'adresse saisie pour réinitialiser votre mot de passe.
					</p>

					<v-text-field
					:error-messages="emailError"
					v-model="form.email"
					label="Adresse mail"
					type="email"
					outlined
					clearable
					color="#2C0703"
					required
					@keydown.space.prevent
					:rules="[$rules.required, $rules.validEmail]"
					/>

					<div class="tw-flex tw-justify-between">
						<ButtonSlot
						@click="closePopin"
						_theme="light-gray"
						>
							Annuler
						</ButtonSlot>

						<ButtonSlot _type="submit">
							Valider
						</ButtonSlot>
					</div>
				</div>
			</v-form>
		</v-card>
	</section>
</template>

<script>
import toastPlugin from "../../plugins/messages";

export default {
	props: {
		userEmail: {
			required: false,
			type: String
		}
	},
	data(){
		return {
			emailError: "",
			form: {
				email: this.userEmail
			}
		};
	},
	methods: {
		closePopin(){
			this.$emit("closePopin");
		},
		sendRecoveryRequest(){
			if(this.$refs.form.validate() === false){
				return;
			}

			toastPlugin.toast("success", "Un mail vient d'être envoyé pour réinitialiser votre mot de passe.");

			this.$emit("closePopin");

			this.$api.users.askRecovery(this.form.email);
		}
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/authentication.scss";

.forgotPasswordPopin {
  @extend %popinView;

  .v-card {
    width: 33%;
  }
}
</style>
