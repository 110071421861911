<template>
  <div>Loading user</div>
</template>

<script>
import auth from "@/service/auth.js";

export default {
  data() {
    return {
      token: ""
    };
  },
  methods: {
    async getUserWhenInStorage() {
      if (false === auth.isLoggedGuest())
        return setTimeout(this.getUserWhenInStorage, 300);

      // get user from db
      let user = await this.$api.guests.getByToken(this.token);
      // convert user and save to store
      user.firstname = user.displayName;
      user.userId = user.id;
      user.job = "";
      user.isInternal = false;

      this.$store.commit("user/setUser", user);

      this.$router.push({
        name: user.campaign.type + "-campaign-dashboard-guest",
        params: {
          id: user.campaign.id
        }
      });
    }
  },
  mounted() {
    const token = this.$route.query.token;
    if (undefined === token || "" === token) return;

    auth.loginGuest(token);

    this.token = token;

    this.getUserWhenInStorage();
  }
};
</script>

<style></style>
